// src/utils/db.js
import { openDB } from 'idb';

// const DB_NAME = 'PocoLocoDB';
// const STORE_NAME = 'config';

export async function saveConfig(dbName, storeName, data, language) {
  const db = await openDB(dbName, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    },
  });
  await db.put(storeName, data, language);
}
export async function getConfig(dbName, storeName, key) {
  const db = await openDB(dbName, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    },
  });
  return await db.get(storeName, key);
}

export async function deleteConfig(dbName, storeName, key) {
  const db = await openDB(dbName, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    },
  });
  await db.delete(storeName, key);
}
