// src/MainApp.jsx
import React, { useContext, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AppContext } from "./context/AppContext";
import LoadingIndicator from "./components/Loading/LoadingIndicator";
import { Box, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Portal = lazy(() => import("./pages/Portal"));
const Store = lazy(() => import("./pages/Store"));
// const PageSelector = lazy(() => import("./components/Layout/PageSelector"));

const MainApp = () => {
  const { state } = useContext(AppContext);
  const { t } = useTranslation();
  const isProduction = process.env.NODE_ENV === "production";
  // const location = useLocation();

  // Gestionarea stării de încărcare
  console.log("state", state.linkState);
  
  // const pathSegments = location.pathname.split('/');
  // let appId = null;

  // if (pathSegments.length > 2) {
  //   const storeSegment = pathSegments[2];
  //   const [id, code] = storeSegment.split('-');
  //   if (id && id !== "-1") appId = id;
  // }

  console.log("state", state.linkState);


  if (
    state.isLoadingInitializeSession 
    // state.isLoadingGetAppInfo ||
    // state.isLoadingGetConfigVersion ||
    // state.isLoadingDownloadNewConfig ||
    // state.isLoadingGetPortalInfo
    // || true
  ) {
    let loadingMessage = t("loading.loading");

    if (state.isLoadingInitializeSession) {
      loadingMessage = t("loading.loadingSession");
   
    }

    return <LoadingIndicator message={loadingMessage} appId={state.linkState.appId} />;
  }

  // Gestionarea erorilor
  if (state.error && !isProduction) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        
      >
        <Alert status="error">
          <AlertIcon />
          <Text>{state.error}</Text>
        </Alert>
      </Box>
    );
  }

  return (
    <Suspense fallback={<LoadingIndicator message="Încărcare..." />}>
      <Routes>
        {/* Ruta pentru Portal */}
        <Route path="/portal/*" element={<Portal />} />

        {/* Ruta pentru Store */}
        <Route path="/store/:appCode/*" element={<Store />} />

        {/* Ruta wildcard pentru redirecționări generale */}
        {/* <Route path="*" element={<Navigate to="/portal/" replace />} /> */}
      </Routes>
    </Suspense>
  );
};

export default MainApp;
