// src/components/Loading/LoadingIndicator.jsx
import React from 'react';
import { Flex, Spinner as ChakraSpinner, Text , Box, Image} from '@chakra-ui/react';
// import { motion } from 'framer-motion';
import {getFormattedDate} from "../../utils/helpers";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// const MotionBox = motion.create(Box); // Folosește motion.create() în loc de motion()

const LoadingIndicator = ({ message, appId }) => {





  console.log("LoadingIndicator rendered with appId:", appId);
  const version = getFormattedDate();
  const logoUrl = appId
  // ? `https://apps-stage.appy.ro/cdn/live/${appId}/img${appId}icon_150.png`
  ? `${API_BASE_URL}cdn/live/${appId}/img${appId}icon_150.png?v=${version}`
  : null;

  console.log("LoadingIndicator rendered logoUrl:", logoUrl);
    return (
        <Flex direction="column" align="center" justify="center" height="100vh">
            {logoUrl && (
                <Image src={logoUrl} alt="App Logo" mb={4} width="150px"  />
            )}
        <ChakraSpinner size="xl" opacity={0.6} />
        <Text opacity={0.6} mt={4}>{message || "Loading ..."}</Text>
      </Flex>
    );
};

export default LoadingIndicator;

