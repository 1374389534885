// config.js
const env = process.env.NODE_ENV || "development";

let envConfig;

if (env === "production") {
  envConfig = require("./config.prod").default;
} else if (env === "development") {
  envConfig = require("./config.dev").default;
} else if(env === "stage"){
  envConfig = require("./config.stage").default;
} else {
  throw new Error(`Mediu necunoscut: ${env}`);
}

module.exports = envConfig;
