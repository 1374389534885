// src/App.jsx
import React from "react";
import MainApp from "./MainApp";
import { ColorModeScript } from "@chakra-ui/react";
import customTheme from "./theme";
import { ImageCacheProvider } from "./context/ImageCacheContext";

const App = () => {
  return (
    <ImageCacheProvider>
      <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
      <MainApp />
    </ImageCacheProvider>
  );
};

export default App;
