// src/utils/helpers.js
// import React from "react";
import { Highlight } from "@chakra-ui/react";
import { saveConfig, getConfig, deleteConfig } from "../utils/db";

export const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const deriveProductsFromConfig = (configXml, appInfo) => {
  const envConfig = require("../config/config");
  console.log(
    "121212 helpers  deriveProductsFromConfig-------------------\n",
    envConfig.ServerURL,
    configXml,
    appInfo
  );
  const ConfigOBJ = {
    AppLanguages: {},
    ECommerce: {},
    MenuList: [],
    ProductsModules: {},
    VisibleGroupings: {},

    ModifiersGroups: {},
    ModifiersGroupsByParent: {},
    Products: {},
    Extras: {},
    CustomFeatures: {},
    Groupings: {},
    GroupsComboOffer: {},
    theme: {},
    Places: {},
  };

  // Helper function to append a suffix before the file extension
  const appendSuffix = (filename, suffix) => {
    const lastDotIndex = filename.lastIndexOf(".");
    // Dacă nu există punct în numele fișierului, adaugă sufixul la final
    if (lastDotIndex === -1) return `${filename}${suffix}`;
    // Separă numele fișierului în parte înainte și după punct
    const name = filename.substring(0, lastDotIndex);
    const extension = filename.substring(lastDotIndex);
    return `${name}${suffix}${extension}`;
  };

  const prepProduct = (prod, product, imgPath, AppItemID) => {
    if (typeof ConfigOBJ.Products[prod.ID] === "undefined") {
      ConfigOBJ.Products[prod.ID] = prod;
    }

    let position = 1;
    if (product.Categories && product.Categories.length > 0) {
      const categ = product.Categories.find(
        (item) => item["$"].ID === AppItemID
      );
      position = categ ? categ["$"].Position : 1;
    }
    prod.Position = position;
    const photos = prod.Img.split(", ").map((photo) => photo.trim());
    const photo1 = photos[0] || "";
    if (photo1 !== "") {
      const imgVer = prod.ImgVersions ? prod.ImgVersions.split(", ")[0] : "";
      prod.ImgLogoURL = imgPath + photo1 + "?v=" + imgVer;
      prod.ImgLogoURL150 =
        imgPath + appendSuffix(photo1, "_150") + "?v=" + imgVer;
      prod.ImgLogoURL600 =
        imgPath + appendSuffix(photo1, "_600") + "?v=" + imgVer;
    } else {
      prod.ImgLogoURL = "";
      prod.ImgLogoURL150 = "";
      prod.ImgLogoURL600 = "";
    }

    prod.Name = decodeHtml(prod.Name);
    prod.Description = decodeHtml(prod.Description);
    prod.NewID = AppItemID + "-" + prod.ID;
    return prod;
  };

  // Verificarea validității configurației
  if (
    !appInfo ||
    !configXml ||
    Object.keys(configXml).length === 0 ||
    !configXml.Application ||
    !configXml.Application.Features ||
    !configXml.Application.Features.Products_WEB ||
    !configXml.Application.Features.Grouping_WEB
  ) {
    console.error("Configurația este invalidă.");
    return ConfigOBJ;
  }

  console.log(`Mediul curent: ${envConfig}`);
  console.log("-------------  Deriving products from config:", configXml);

  const isProduction = process.env.NODE_ENV === "production";

  // const appId = config.Application["$"].AppID;
  const appId = appInfo.AppID;
  const agencyName = appInfo.AgencyName;

  const MenuList = [];
  const VisibleGroupings = {};
  const ProductsModules = {};

  const allGroupings = {};
  // 1. Filtrarea Grupurilor Vizibile
  if (
    configXml.Application.Features.Grouping_WEB &&
    Array.isArray(configXml.Application.Features.Grouping_WEB)
  ) {
    Object.entries(configXml.Application.Features.Grouping_WEB).forEach(
      ([key, entry]) => {
        const group = entry["$"];
        allGroupings[group.AppItemID] = group;
      }
    );
  } else {
    const group = configXml.Application.Features.Grouping_WEB["$"];
    allGroupings[group.AppItemID] = group;
  }

  Object.entries(allGroupings).forEach(([key, entry]) => {
    if (String(entry.Status) === "1") {
      const isRootGrouping = entry.GroupingAppItemID === 0;
      const hasValidParentGrouping =
        !isRootGrouping && allGroupings[entry.GroupingAppItemID];

      if (isRootGrouping || hasValidParentGrouping) {
        VisibleGroupings[key] = entry;
      }
    }
  });

  // 2. Crearea Meniului pe Bază de Grupuri și Categorii
  console.log("agencyName", agencyName);
  console.log("allGroupings", allGroupings);

  configXml.Application.Features.Products_WEB.forEach((module) => {
    const mod = module["$"];
    let imgPath = "";
    if (isProduction) {
      imgPath =
        // window.origin +
        window.location.protocol +
        "//" +
        envConfig[agencyName].agencySubDomanin +
        "/cdn/live/" +
        appId +
        // $.getParam("AppID") +
        "/";
    } else {
      imgPath =
        window.location.protocol +
        "//" +
        envConfig[agencyName].agencySubDomanin +
        "/cdn/live/" +
        appId +
        "/";
    }
    console.log("imgPath", imgPath);
    const { AppItemID, GroupingAppItemID } = mod;

    // Verificăm dacă modulul face parte dintr-un grup vizibil
    if (AppItemID === "0") {
      // Gestionarea produselor extras
      if (module.Product && Array.isArray(module.Product)) {
        module.Product.forEach((product) => {
          const prod = product["$"];
          if (typeof ConfigOBJ.Extras[prod.ID] === "undefined") {
            ConfigOBJ.Extras[prod.ID] = prod;
          }
        });
      }
    } else {
      ProductsModules[AppItemID] = mod;

      if (
        GroupingAppItemID === 0 ||
        (allGroupings[GroupingAppItemID] && GroupingAppItemID !== 0)
      ) {
        mod.GroupPosition = allGroupings[GroupingAppItemID]
          ? allGroupings[GroupingAppItemID].Position
          : 0;
        mod.Products = [];
        // console.log("module", module);

        if (module.Product && module.Product["$"]) {
          // Un singur produs
          const product = module.Product;
          const prod = product["$"];
          mod.Products.push(prepProduct(prod, product, imgPath, AppItemID));
        } else if (Array.isArray(module.Product)) {
          // Mai multe produse
          module.Product.forEach((product) => {
            const prod = product["$"];
            mod.Products.push(prepProduct(prod, product, imgPath, AppItemID));
          });
          MenuList.push(mod);
        }
      }
    }
  });

  // Populează ConfigOBJ cu datele procesate
  ConfigOBJ.MenuList = MenuList;
  ConfigOBJ.ProductsModules = ProductsModules;
  ConfigOBJ.VisibleGroupings = VisibleGroupings;

  //pages
  // generate navLinks based on the config
  //todo
  const navLinks = [
    { Title: "home", Path: "menu", SubMenu: [] },
    { Title: "contact", Path: "contact", SubMenu: [] },
    { Title: "about", Path: "about", SubMenu: [] },
    { Title: "Galerie", Path: "galery", SubMenu: [] },
    // { Title: 'Locatii', Path: '/applications',SubMenu:[] },
    // { Title: 'subneniuri', Path: '/restaurants',SubMenu:[] },
  ];

  ConfigOBJ.Pages = navLinks;

  console.log("-------------------ConfigOBJ", ConfigOBJ);
  return ConfigOBJ;
};

// Funcția pentru a actualiza coșul după o nouă configurație
export const updateCartAfterNewConfig = (cart, configOBJ) => {
  const updatedCart = cart.filter((item) => {
    const product = configOBJ.ProductsModules[item.id];
    if (!product) return false; // Produsul nu mai există
    if (
      product.GroupingAppItemID !== 0 &&
      !configOBJ.VisibleGroupings[product.GroupingAppItemID]
    )
      return false; // Grupul produsului nu este vizibil
    return true;
  });

  return updatedCart;
};

export const setLocalStorage = (appCode, key, value) => {
  const prefixedKey = `${appCode}-${key}`;
  localStorage.setItem(prefixedKey, JSON.stringify(value));
};

export const getLocalStorage = (appCode, key) => {
  const prefixedKey = `${appCode}-${key}`;
  const value = localStorage.getItem(prefixedKey);
  return value ? JSON.parse(value) : null;
};

export const removeLocalStorage = (appCode, key) => {
  const prefixedKey = `${appCode}-${key}`;
  localStorage.removeItem(prefixedKey);
};

// export const getConfigFromLocal = (appCode, language) => {
//   const configKey = `config-${language}`;
//   const config = getLocalStorage(appCode, configKey);
//   return config;
// };

// export const setConfigToLocal = (appCode, config, version, language) => {
//   const configKey = `config-${language}`;
//   setLocalStorage(appCode, configKey, config);
//   setLocalStorage(appCode, `${configKey}-version`, version);
// };

// Cache intern pentru a simula comportamentul sincron al localStorage
let configCache = {};

export const getConfigFromLocal = async (appCode, language) => {
 
  try {
    const config = await getConfig(appCode, "config", language);
    return config;
  } catch (error) {
    console.error("Error getting config from IndexedDB:", error);
    return null;
  }
};

export const setConfigToLocal = async (appCode, config, version, language) => {
  try {
    await saveConfig(appCode, "config", config, language);
    // Salvăm și versiunea configurației, dacă este necesar
    //await saveConfig(appCode, "version", version, language);
     setLocalStorage(appCode, `config-${language}-version`, version);
  } catch (error) {
    console.error("Error saving config to IndexedDB:", error);
  }
};

export const getColorFromTheme = (colorString, defaultTheme) => {
  if (!colorString) return "#319795"; // default color
  const [colorName, colorShade] = colorString.split(".");
  if (
    defaultTheme.colors[colorName] &&
    defaultTheme.colors[colorName][colorShade]
  ) {
    return defaultTheme.colors[colorName][colorShade];
  }
  // Return a default color if the colorString is invalid
  return "#319795";
};

export const isIP = (hostname) => {
  // Expresie regulată pentru IPv4
  const ipv4Pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  // Expresie regulată pentru IPv6
  const ipv6Pattern = /^(([0-9a-fA-F]{1,4}:){7}([0-9a-fA-F]{1,4}|:)|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}(:[0-9a-fA-F]{1,4}){1,5}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,6}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,7}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,8}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,9}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,9}|:))$/;

  return ipv4Pattern.test(hostname) || ipv6Pattern.test(hostname);
};

// export const highlightText = (text, searchTerm, highlightStyle) => {
//   if (!searchTerm) return text;
//   const regex = new RegExp(`(${searchTerm})`, "gi");
//   const parts = text.split(regex);
//   return parts.map((part, index) =>
//     regex.test(part) ? (
//       <span key={index} style={highlightStyle}>
//         {part}
//       </span>
//     ) : (
//       part
//     )
//   );
// };

// export const highlightText = (text, searchTerm, highlightStyle) => {
//   if (!searchTerm) return text;

//   const regex = new RegExp(`(${searchTerm})`, "gi");
//   const parts = text.split(regex);

//   return parts.map((part, index) =>
//     regex.test(part) ? (
//       <Badge key={index} colorScheme={highlightStyle.colorScheme}>
//         {part}
//       </Badge>
//     ) : (
//       part
//     )
//   );
// };

export const highlightText = (text, searchTerm) => {
  if (!searchTerm) return text;

  const terms = searchTerm.split(" ").filter(Boolean);
  return (
    <Highlight
      query={terms}
      styles={{
        px: "0.5",
        py: "0.5",
        bg: "yellow.200",
        color: "black",
        borderRadius: "md",
      }}
    >
      {text}
    </Highlight>
  );
};

// Funcție helper pentru extragerea parametrilor cu prefix
export const extractParam = (paramWithPrefix, prefix) => {
  if (paramWithPrefix && paramWithPrefix.startsWith(prefix)) {
    return paramWithPrefix.slice(prefix.length);
  }
  return null;
};




export const generatePathFromLinkState = (linkState) => {
  // Destructurarea valorilor din linkState cu valori implicite
  const {
    route = "portal",
    language = "en",
    appCode = "noAppCode",
    appId = "0",
    appDomain = "",
    page = "menu",
    search = null,
    category = null,
    product = null,
  } = linkState;

  let newPathSegments = [];

  if (route === "store") {
    newPathSegments = [
      route,
      appId+'-'+appCode,
      `l_${language}`,
      `p_${page}`,
      search ? `s_${search}` : null,
      category ? `c_${category}` : null,
      product ? `pr_${product}` : null,
    ].filter(Boolean); // Elimină valorile nule
  } else if (route === "portal") {
    newPathSegments = [route];
  }

  return `/${newPathSegments.join("/")}`;
};


 // Funcție pentru a obține data curentă în format YYYYMMDD
export const getFormattedDate = () => {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');
  return `${yyyy}${mm}${dd}`;
};