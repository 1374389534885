// src/context/ImageCacheContext.jsx
import React, { createContext, useState } from "react";

export const ImageCacheContext = createContext();

export const ImageCacheProvider = ({ children }) => {
  const [loadedImages, setLoadedImages] = useState(new Set());

  const markImageAsLoaded = (url) => {
    setLoadedImages((prev) => new Set(prev).add(url));
  };

  return (
    <ImageCacheContext.Provider value={{ loadedImages, markImageAsLoaded }}>
      {children}
    </ImageCacheContext.Provider>
  );
};
