// config.prod.js
const envConfig = {
  env: "production",
  ServerURL: "//apps.appy.ro/",
  cacheInterval: "120",
  version: "25.9.24.22.40",
  default: {
    logoUrl: "//apps.appy.ro/admin/agencies/Appy/logo.png",
    agencySubDomanin: "apps.appy.ro",
    www: "https://appy.ro/restaurants/",
    qrPage:
      "https://appy.ro/meniu-qr-complet-cu-valori-nutritionale/index.html",
    qrName: "APPY QR",
  },
  Appy: {
    logoUrl: "//apps.appy.ro/admin/agencies/Appy/logo.png",
    agencySubDomanin: "apps.appy.ro",
    www: "https://appy.ro/restaurants/",
    qrPage:
      "https://appy.ro/meniu-qr-complet-cu-valori-nutritionale/index.html",
    qrName: "APPY QR",
  },
  Multisoft: {
    logoUrl: "//online.soloncloud.ro/admin/agencies/Multisoft/logo.png",
    agencySubDomanin: "online.soloncloud.ro",
    www: "https://multisoft.ro/",
    qrPage: "https://multisoft.ro/",
    qrName: "Multisoft QR",
  },
  Zynox: {
    logoUrl: "//horeca.zynox.ro/admin/agencies/Zynox/logo.png",
    agencySubDomanin: "horeca.zynox.ro",
    www: "https://Zynox.ro/",
    qrPage: "https://Zynox.ro/",
    qrName: "Zynox QR",
  },
  AppzBizz: {
    logoUrl: "//www.alphatech.technology/images/images/logo-abz-small.jpg",
    agencySubDomanin: "applegacy.alphatech.technology",
    www: "https://alphatech.technology",
    qrPage:
      "https://appy.ro/meniu-qr-complet-cu-valori-nutritionale/index.html",
    qrName: "APPY QR",
  },
  agencySubDomains:
    "localhost,applegacy.alphatech.technology,appstagelegacy.alphatech.technology,apps.appy.ro,apps-stage.appy.ro,online.soloncloud.ro,horeca.zynox.ro,shop.appy.ro,shop-stage.appy.ro,shop.zynox.ro",
  agencySubDomainsObj: {
    localhost: "default",
    "applegacy.alphatech.technology": "AppzBizz",
    "apps.appy.ro": "Appy",
    "shop.appy.ro": "Appy",
    "shop-stage.appy.ro": "Appy",
    "online.soloncloud.ro": "Multisoft",
    "horeca.zynox.ro": "Zynox",
  },
};

export default envConfig;
