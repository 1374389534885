// src/theme/index.js
import { extendTheme } from "@chakra-ui/react";

const defaultTheme = extendTheme({
  config: {
    initialColorMode: "system", // Utilizează tema sistemului
    useSystemColorMode: true, // Activează detectarea automată a temei sistemului
  },
  colors: {
    primary: {
      light: "teal.500",
      dark: "teal.300",
    },
    // background: {
    //   light: "gray.50",
    //   dark: "gray.800",
    // },
    componentBg: {
      productBox: {
        light: "white",
        dark: "gray.700",
      },
      cart: {
        light: "white",
        dark: "gray.700",
      },
      header: {
        light: "white",
        dark: "gray.900",
      },
      footer: {
        light: "white",
        dark: "gray.600",
      },
      mobileFooter: {
        light: "white",
        dark: "gray.700",
      },
      mainPage: {
        light: "gray.50",
        dark: "gray.800",
      },
      cartModal: {
        light: "white",
        dark: "gray.700",
      },
      userModal: {
        light: "white",
        dark: "gray.700",
      },
    },
    componentText: {
      productBox: {
        light: "black",
        dark: "white",
      },
      cart: {
        light: "black",
        dark: "white",
      },
      header: {
        light: "black",
        dark: "white",
      },
      footer: {
        light: "black",
        dark: "white",
      },
      mainPage: {
        light: "black",
        dark: "white",
      },
      CartModal: {
        light: "black",
        dark: "white",
      },
      UserModal: {
        light: "black",
        dark: "white",
      },
      mobileFooter: {
        light: "black",
        dark: "white",
      },
    },
  },
  fonts: {
    heading: "Roboto, sans-serif",
    body: "Open Sans, sans-serif",
  },
  components: {
    Box: {
      variants: {
        productBox: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.productBox.light
              : defaultTheme.colors.componentBg.productBox.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.productBox.light
              : defaultTheme.colors.componentText.productBox.dark,
          borderRadius: "lg",
          boxShadow: "md",
          padding: 4,
        }),
        cart: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.cart.light
              : defaultTheme.colors.componentBg.cart.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.cart.light
              : defaultTheme.colors.componentText.cart.dark,
          borderRadius: "lg",
          boxShadow: "md",
          padding: 4,
        }),
        header: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.header.light
              : defaultTheme.colors.componentBg.header.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.header.light
              : defaultTheme.colors.componentText.header.dark,
          boxShadow: "sm",
          padding: 4,
        }),
        footer: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.footer.light
              : defaultTheme.colors.componentBg.footer.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.footer.light
              : defaultTheme.colors.componentText.footer.dark,
          boxShadow: "sm",
          padding: 4,
        }),
        mobileFooter: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.mobileFooter.light
              : defaultTheme.colors.componentBg.mobileFooter.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.mobileFooter.light
              : defaultTheme.colors.componentText.mobileFooter.dark,
          boxShadow: "sm",
          padding: 4,
        }),
        mainPage: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.mainPage.light
              : defaultTheme.colors.componentBg.mainPage.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.mainPage.light
              : defaultTheme.colors.componentText.mainPage.dark,
          //   padding: 4,
        }),
      },
    },
    Modal: {
      variants: {
        CartModal: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.CartModal.light
              : defaultTheme.colors.componentBg.CartModal.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.CartModal.light
              : defaultTheme.colors.componentText.CartModal.dark,
        }),
        UserModal: (props) => ({
          bg:
            props.colorMode === "light"
              ? defaultTheme.colors.componentBg.UserModal.light
              : defaultTheme.colors.componentBg.UserModal.dark,
          color:
            props.colorMode === "light"
              ? defaultTheme.colors.componentText.UserModal.light
              : defaultTheme.colors.componentText.UserModal.dark,
        }),
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg:
          props && props.colorMode === "light"
            ? defaultTheme.colors.componentBg.mainPage.light
            : defaultTheme.colors.componentBg.mainPage.dark,
        color:
          props && props.colorMode === "light"
            ? defaultTheme.colors.componentText.mainPage.light
            : defaultTheme.colors.componentText.mainPage.dark,
      },
    }),
  },
});

export default defaultTheme;
